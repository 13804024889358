@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500&display=swap");

.subrayado {
  text-decoration: none;
}

.footerLetra {
  font-family: "Montserrat", sans-serif;
  color: whitesmoke;
}

.footerLetra a {
  font-weight: 300;
} 

/* .positionFooter {
  margin-top: auto;
  background-color: rgb(0, 0, 0);
} */

@media all and (min-width: 330px) and (max-width: 767px) {
    .logoImg {
      width: 290px;
    }
  }

@media all and (min-width: 768px) and (max-width: 991px) {
  .logoImg {
    width: 300px;
    padding-right: 70px;
  }
}

@media all and (min-width: 992px)  {
    .logoImg {
      width: 390px;
      padding-right: 70px;
    }
  }
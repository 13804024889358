.bgColor {
  background-color: rgba(0, 0, 0, 0.1);
  height: auto;
}

.iconoContacto {
  font-size: 30px;
  color: rgba(18, 66, 223, 0.45);
}

@media all and (min-width: 320px) and (max-width: 505px) {
  .textoC {
    font-size: 0.7em;
  }
}

@media all and (min-width: 768px) and (max-width: 992px) {
  .textoC {
    font-size: 0.85em;
  }
}

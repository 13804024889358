.sitiosBg {
    width: 100%;
    border: 1px solid rgb(189, 186, 186);
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.imgMinis {
    width: 245px;
    height: auto;
}

.imgAbog {
    width: 170px;
}

@media all and (min-width: 300px) and (max-width: 991px) {
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

.bgColor {
    background-color: rgba(0, 0, 0, 0.1);
    height: auto;
}

.fontW {
    font-family: "Montserrat", sans-serif;
    font-weight: 500 !important;
}

.portadaUno {
    background: url('../../img/drogueriaCarouselUno.jpeg') no-repeat center/cover;
    width: 100%;
    height: 450px;
    filter: grayscale(40%);
}

.portadaUno:hover {
    transform: scale(1.5);
    filter: grayscale(0%);
    transition: 800ms;
}

.portadaDos {
    background: url('../../img/drogueriaCarouselDos.jpeg') no-repeat center/cover;
    width: 100%;
    height: 450px;
    filter: grayscale(40%);
}

.portadaDos:hover {
    transform: scale(1.5);
    filter: grayscale(0%);
    transition: 800ms;
}

.portadaTres {
    background: url('../../img/drogueriaCarousel-Tres.jpeg') no-repeat center/cover;
    width: 100%;
    height: 450px;
    filter: grayscale(40%);
}

.portadaTres:hover {
    transform: scale(1.5);
    filter: grayscale(0%);
    transition: 800ms;
}

.titulo {
    width: 100%;
    height: auto;
    background-color: rgba(18, 66, 223, 0.781);
}

.tituloMov {
    animation: myMov;
    animation-play-state: running;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-duration: 2900ms;
}

@keyframes myMov{
    from{margin-left: 100%;}
    to{margin-left: 0%;}
}

.iconoBg {
    font-size: 90px;
    color: rgba(18, 66, 223, 0.45);
    background-color: aliceblue;
    width: 150px;
    height: 150px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.5);
    margin: 15px;
    border-radius: 75px 75px 75px 75px;
}

.iconoBg:hover {
    transform: scale(1.2);
    transition: 850ms;
}

.btnIconos {
    width: 180px;
    height: 50px;
    background-color: rgba(18, 66, 223, 0.45);
    border-radius: 50px 50px 50px 50px;
}

.iconoBtn {
    animation: mySlide;
    animation-play-state: running;
    animation-iteration-count: 1;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-duration: 1500ms;
}

@keyframes mySlide{
    from{margin-top: 100%;}
    to{margin-bottom: 0%;}
}

.iconoTexto {
    font-size: 80px !important;
    margin-top: 50px;
    padding-left: 40px;
    color: rgba(18, 66, 223, 0.781);
}

.carouselInfo {
    width: 100%;
    height: 350px;
    background-color: aliceblue;
}

.texto {
    width: 100%;
    height: auto;
}

.fotoAvatar {
    margin-top: 90px;
}

.parrafo {
    margin-top: 90px;
}

.containerText {
    background: rgba(0, 0, 0, 0.3);
    padding: 90px;
    margin-top: 30px;
    margin-bottom: 30px;
    transform: skew(0deg, -2.7deg);
}

.containerText .content{
    transform: skew(0deg, 2.7deg);
    text-align: center;
}

.tituloNos {
    color: rgba(18, 66, 223, 0.45);
}

.tituloSer {
    color: rgba(18, 66, 223, 0.781);
    font-weight: 600;
}

.fotoAutoridades {
    width: 100%;
}

ul li::marker {
    font-size: 22px;
    color: rgba(18, 66, 223, 0.781);
}


@media all and (min-width: 300px) and (max-width: 690px) {
    .carouselInfo {
        width: 100%;
        height: auto;
        padding: 20px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .containerText {
        padding: 15px;
    }

    .parrafo {
        margin-bottom: 15px;
        margin-top: 40px;
    }

    .iconoTexto {
        margin: 2px;
        padding: 5px;
    }

    .fotoAvatar {
        margin-top: 40px;
        width: 150px;
    }
}

@media all and (min-width: 691px) and (max-width: 1068px) {
    .carouselInfo {
        width: 100%;
        height: auto;
    }

    .parrafo {
        margin-bottom: 35px;
        margin-top: 40px;
    }
}

@media all and (min-width: 1068px) and (max-width: 2068px) {
    .fotoAutoridades {
        width: 80%; 
    }
}


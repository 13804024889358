.mapResponsive {
    overflow: hidden;
    padding: 15%;
    position: relative;
    height: 0;
    border-radius: 15px;
}

.mapResponsive iframe {
    left: 0;
    top: 0;
    border: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@media all and (min-width: 320px) and (max-width: 759px) {
    .mapResponsive {
        height: 280px;
    }
  }